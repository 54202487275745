import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setGambleStakeResult, setIsCollected } from '../../gql/cache';
import { updateCoinValueAfterBonuses } from '../../utils';
import ViewContainer from '../components/container';
import { eventManager } from '../config';
import { gambleResult } from '../d';

import { BgSkin, backGroundGameModeSkins } from './config';

class Background extends ViewContainer {
  private bgSprite = new PIXI.Sprite();

  private currentSkin?: GameMode;

  private isGambleMode = false;

  constructor() {
    super();

    this.bgSprite.anchor.set(0.5);
    this.sortableChildren = true;
    this.addChild(this.bgSprite);

    this.setSkin(GameMode.BASE_GAME);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  public setSkin(mode: GameMode) {
    if (this.currentSkin === mode) return;
    this.currentSkin = mode;
    this.bgSprite.texture = PIXI.Texture.from(backGroundGameModeSkins[mode]!.bg);
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    this.setSkin(settings.mode);

    if (settings.mode == GameMode.BASE_GAME_GAMBLE) {
      this.isGambleMode = true;
      eventManager.emit(EventTypes.IS_GAMBLE_MODE, true);
    } else if (settings.mode == GameMode.FREE_SPIN) {
      this.isGambleMode = false;
      eventManager.emit(EventTypes.IS_GAMBLE_MODE, false);
    } else {
      if (
        (setGambleStakeResult() === gambleResult.limit || setGambleStakeResult() === gambleResult.loss) &&
        setIsCollected()
      ) {
        eventManager.emit(EventTypes.IS_GAMBLE_MODE, false);
      }
      if (this.isGambleMode) {
        updateCoinValueAfterBonuses();
        this.isGambleMode = false;
      }
      this.bgSprite.filters = null;
    }
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }
}

export default Background;
