import _ from 'lodash';
import { AdjustmentFilter } from 'pixi-filters';
import * as PIXI from 'pixi.js';
import { Loader } from 'pixi.js';

import { ELoaderStages, ILoaderResource } from '@phoenix7dev/shared-components/dist/loader/d';
import { formatNumber as utilsFormatNumber } from '@phoenix7dev/utils-fe';

import { config, red } from '../config';
import { EventTypes, GameMode, ReelId } from '../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setSlotConfig,
  setStressful,
  setTalks,
} from '../gql/cache';
import i18n from '../i18next';
import {
  BASE_WIN_AMOUNT_LIMIT,
  BIG_WIN_AMOUNT_LIMIT,
  DOUBLE_WIN_AMOUNT_LIMIT,
  GREAT_WIN_AMOUNT_LIMIT,
  MAXIMUM_FRACTION_DIGITS,
  MEGA_WIN_AMOUNT_LIMIT,
  MINIMUM_FRACTION_DIGITS,
  WinStages,
  eventManager,
} from '../slotMachine/config';
import { Features } from '../slotMachine/d';

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, PIXI.LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    PIXI.Loader.shared.load((_loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
  });
};
export const loadPixiAssets = (assets: PIXI.IAddOptions[], baseUrl: string): Promise<void> => {
  Loader.shared.baseUrl = baseUrl;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    PIXI.Loader.shared.add(assets);
    let tries = config.failureRetries;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (err) {
        console.error(err);
      }
    }

    return success ? resolve() : reject();
  });
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const getCssVariable = (cssVar: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVar);
};

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (width < height) {
    return height * (parseInt(getCssVariable('--bottom-height-percent-portrait'), 10) / 100);
  }
  return height * (parseInt(getCssVariable('--bottom-height-percent-landscape'), 10) / 100);
};
export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0, coinValue = setCoinValue()): number => {
  return (coins * coinValue) / 100;
};
export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};
export const formatNumber = (currency = 'FUN', value = 0, showCurrency = false): string => {
  return utilsFormatNumber({
    currency,
    value,
    showCurrency,
    minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
    maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
  });
};

export const nextTick = (callback: () => void): number => window.setTimeout(callback, 0);

export const getStopReel = (reel: number): number => {
  let stopReelId = 0;
  switch (reel) {
    case 0:
      stopReelId = 0;
      break;
    case 1:
      stopReelId = 3;
      break;
    case 2:
      stopReelId = 7;
      break;
    case 3:
      stopReelId = 4;
      break;
    case 4:
      stopReelId = 8;
      break;
    case 5:
      stopReelId = 2;
      break;
    case 6:
      stopReelId = 5;
      break;
    case 7:
      stopReelId = 1;
      break;
    case 8:
      stopReelId = 6;
      break;
  }
  return stopReelId;
};

export const updateTextScale = (text: PIXI.Text, maxWidth: number, maxHeight: number, minVal = 1): void => {
  text.scale.set(1, 1);
  text.updateText(true);
  const ratio = Math.min(minVal, Math.min(maxWidth / text.width, maxHeight / text.height));
  text.scale.set(ratio, ratio);
};

// セリフ開始タイミング
export const getStartTalkTiming = (timing: number): number => {
  return Math.floor(Math.random() * timing);
};

// 過去2セリフチェック
export const chkTalkHistory = (word: number): boolean => {
  if (word === 10000) {
    return true;
  }
  return setTalks().some((element) => element === word);
};

// 過去2セリフへ登録
export const setTalkHistory = (word: number): void => {
  setTalks().push(word);
  if (setTalks().length >= 3) {
    setTalks().shift();
  }
};

// セリフ抽選時の繰り返し回避処理
export const repeatAvoidance = (voiceArray: number[]): number => {
  let index = 0;

  for (let i = 0; i < 10; i++) {
    index = Math.floor(Math.random() * voiceArray.length);
    if (!chkTalkHistory(voiceArray[index]!)) {
      // debugDisplay(i + 'th  lot ' + ',history ' + setTalks());
      break;
    } else if (i === 9) {
      debugDisplay(red + i + 'th  lot ' + ',history ' + setTalks());
    }
  }
  setTalkHistory(voiceArray[index]!);
  return voiceArray[index]!;
};

export const countStage = (bet: number, win: number): WinStages => {
  const multiplier = win / bet;
  if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
    return WinStages.None;
  }
  if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
    return WinStages.BaseWin;
  }
  if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
  if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
  return WinStages.EpicWin;
};

export const isDropAnimation = (anim: string): boolean => {
  let rtn = false;
  if (
    anim === '6_1s' ||
    anim === '7_1c' ||
    anim === '8_1t3t2t1s' ||
    anim === '9_2t1s' ||
    anim === '10_2t1c' ||
    anim === '11_2t1t3t1s' ||
    anim === '12_3t2t1s' ||
    anim === '13_3t2t1c' ||
    anim === '14_2s' ||
    anim === '15_2c' ||
    anim === '16_1t3t2s' ||
    anim === '17_1t3t2c' ||
    anim === '18_3t2s' ||
    anim === '19_3t2c' ||
    anim === '20_3s' ||
    anim === '21_3c' ||
    anim === '22_1t3s' ||
    anim === '23_1t3c' ||
    anim === '24_2t1t3s' ||
    anim === '25_2t1t3c' ||
    anim === '26_3t2t1t3s'
  ) {
    rtn = true;
  }
  return rtn;
};

export const debugDisplay = (..._prams: unknown[]): void => {
  console.log(_prams);
};

export const isBuyFeatureEnabled = (features: Features[] = []): boolean => {
  const freeSpinFeature = features.find((i) => i.id === 'freeSpins');

  return freeSpinFeature?.enabled || false;
};

export const filterMouseOver = (): AdjustmentFilter => {
  return new AdjustmentFilter({ contrast: 0.7, brightness: 1.5, red: 2, green: 2 });
  // return new AdjustmentFilter({ contrast: 0.95, brightness: 1.5, red: 2, green: 2 });
};

export const filterMouseDown = (): AdjustmentFilter => {
  return new AdjustmentFilter({ contrast: 0.7, brightness: 1.5, red: 1.2, green: 1, blue: 2 });
  // return new AdjustmentFilter({ contrast: 0.95, brightness: 1.5, red: 2, green: 2 });
};

export const filterDisable = (): AdjustmentFilter => {
  return new AdjustmentFilter({ contrast: 0.7, brightness: 0.3, red: 1.1, green: 1, blue: 1.4 });
};

export const filterGamble = (): AdjustmentFilter => {
  return new AdjustmentFilter({
    gamma: 0.92,
    saturation: 0.65,
    contrast: 0.92,
    brightness: 0.6,
    red: 0.87,
    green: 2.34,
    blue: 0.76,
  });
};

export const filterGambleSpinReelFrame1 = (): AdjustmentFilter => {
  return new AdjustmentFilter({
    gamma: 5,
    saturation: 5,
    contrast: 5,
    brightness: 5,
    red: 1.0,
    green: 1.0,
    blue: 1.0,
  });
};

export const filterGambleSpinReelFrame2 = (): AdjustmentFilter => {
  return new AdjustmentFilter({
    gamma: 1.0,
    saturation: 1.0,
    contrast: 1.0,
    brightness: 1.0,
    red: 1.0,
    green: 1.0,
    blue: 1.0,
  });
};

export const filterBaseReelFrame = (): AdjustmentFilter => {
  return new AdjustmentFilter({
    gamma: 1.0,
    saturation: 1.0,
    contrast: 1.0,
    brightness: 1.0,
    red: 1.0,
    green: 1.0,
    blue: 1.0,
  });
};

export const isFreeSpinsMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPIN;
};

export const isGambleMode = (mode: GameMode): boolean => {
  return mode === GameMode.BASE_GAME_GAMBLE;
};

export const isBaseReelSet = (reelSetId: string): boolean => {
  return ReelId.BASE_GAME === reelSetId;
};

export const loadErrorHandler = (error?: Error, resources?: ILoaderResource[]): void => {
  const stage = resources?.find((r) => !!r.error);
  const errorMsg = stage?.error as unknown as string;
  switch (stage?.name) {
    case ELoaderStages.AUTH:
      if (setStressful().show && setStressful().message === i18n.t(['errors.UNKNOWN.UNKNOWN'])) {
        break;
      }
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t(['errors.CLIENT.INVALID_CLIENT_TOKEN', 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
      break;
    default:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t([errorMsg === 'Failed to fetch' ? 'errors.UNKNOWN.NETWORK' : 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
  }
};

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i]!;

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0;
};

export const updateCoinValueAfterBonuses = (): void => {
  // updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
  const coinValue = setSlotConfig().clientSlotSettings.coinValueSettings.find((elem) => elem.code === setCurrency())
    ?.variants[0];
  const coinAmount = findSubstituteCoinAmount(
    setCoinAmount(),
    setSlotConfig().clientSlotSettings.betCoinAmountSettings,
  );
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * setSlotConfig().slotSettings.globalCoinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};
