import { Application, Container, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { introContents } from '../../config/introContents';
import { EventTypes } from '../../global.d';
import { setIsSoundOn, setProgress } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { eventManager } from '../../slotMachine/config';
import SoundBtn from '../../slotMachine/controlButtons/soundBtn';
import { UiButton } from '../../slotMachine/ui/uiButton';
import { calcPercentage, getCssVariable } from '../../utils';

import Carousel from './Carousel/index';

class IntroScreen {
  private readonly app: Application;

  private static introScreen: IntroScreen;

  private container = new Container();

  public static init = (application: Application): void => {
    IntroScreen.introScreen = new IntroScreen(application);
  };

  // private controlsContainer: Container;

  public static initIntroScreen = (application: Application): void => {
    IntroScreen.introScreen = new IntroScreen(application);
  };

  public static getInstance = (): IntroScreen => IntroScreen.introScreen;

  private background = new Sprite(Texture.from(ResourceTypes.introBg));

  private backgroundContainer = new Container();

  private playBtn: UiButton;

  private soundBtn: SoundBtn;

  private carousel: Carousel;

  private boundResize = this.resize.bind(this);

  constructor(application: Application) {
    this.app = application;
    this.background.anchor.set(0.5);
    this.backgroundContainer.addChild(this.background);

    this.container.addChild(this.backgroundContainer);
    // this.application.stage.addChild(this.backgroundContainer);

    this.playBtn = this.initPlayBtn();
    this.soundBtn = new SoundBtn();
    this.carousel = new Carousel(introContents);
    this.container.addChild(this.carousel, this.playBtn, this.soundBtn);

    this.app.stage.addChild(this.container);
    eventManager.addListener(EventTypes.RESIZE, this.boundResize);
    eventManager.on(EventTypes.SOUND_INITIALIZED, () => {
      AudioApi.setSoundState(setIsSoundOn());
    });
  }

  private initPlayBtn(): UiButton {
    const btn = new UiButton('play');
    btn.btn.anchor.set(1, 0.5);
    btn.interactive = true;

    const clickCallback = () => {
      AudioApi.restrictionChangedOnIntroScreen = true;

      BgmControl.handleChangeRestriction();

      setProgress({ ...setProgress(), wasLoaded: true });

      setTimeout(() => {
        this.destroy();
        eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
      });
    };
    btn.once('click', clickCallback);
    btn.once('touchend', clickCallback);

    return btn;
  }

  private setBgSize = (width: number, height: number): void => {
    this.backgroundContainer.x = width / 2;
    this.backgroundContainer.y = height / 2;

    const bgAspectRatio = this.background.width / this.background.height;
    const aspectRatio = width / height;
    if (bgAspectRatio > aspectRatio) {
      this.backgroundContainer.scale.set(height / this.background.height);
    } else {
      this.backgroundContainer.scale.set(width / this.background.width);
    }
  };

  private resize(width: number, height: number): void {
    const isPortraitMode = width < height;
    const gap = calcPercentage(width, 1.25);
    const playBtnGap = calcPercentage(width, 5);

    this.setBgSize(width, height);
    const heightWithBottomGap = isPortraitMode
      ? calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-portrait'), 10))
      : calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-landscape'), 10));

    this.playBtn.btn.anchor.set(1, 1);
    this.playBtn.x = width - playBtnGap;
    this.playBtn.y = height - this.playBtn.height / 2;
    this.playBtn.setSize(calcPercentage(width, 20));

    this.soundBtn.btn.anchor.set(0, 0);
    this.soundBtn.x = gap;
    this.soundBtn.y = gap;
    this.soundBtn.setSize(calcPercentage(width, 7.5));

    this.carousel.setSize(width, heightWithBottomGap, height - heightWithBottomGap);

    if (isPortraitMode) {
      this.soundBtn.btn.anchor.set(1, 0);
      this.soundBtn.x = width - gap;
      this.soundBtn.y = gap;
      this.soundBtn.setSize(calcPercentage(height, 7.5));

      this.playBtn.btn.anchor.set(0.5, 0);
      this.playBtn.y = this.carousel.getBounds().y + this.carousel.height + 10;
      this.playBtn.x = width / 2;
      this.playBtn.setSize(calcPercentage(height - calcPercentage(height, 7.5), 17));
    }
    this.app.renderer.resize(width, height);
  }
  private destroy() {
    eventManager.removeListener(EventTypes.RESIZE, this.boundResize);
    this.container.destroy({ children: true });
  }
}

export default IntroScreen;
