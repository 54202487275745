import * as PIXI from 'pixi.js';

import SlotMachine from '..';
import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import AnimationChain from '../animations/animationChain';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  RESPONSE_WAITING_LOGO_DISPLAY_START_TIME,
  SlotMachineState,
  Z_INDEX_WAITING_LOGO,
  eventManager,
} from '../config';

export class ResponseWaitingLogo extends ViewContainer {
  private logo = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.waitingLogo));

  private rotateAnimation: AnimationChain | null = null;

  constructor() {
    super();

    this.logo.visible = false;
    this.logo.anchor.set(0.5, 0.5);
    this.logo.position.set(GAME_CONTAINER_WIDTH / 2, GAME_CONTAINER_HEIGHT / 2 - 70);
    this.logo.scale.set(1.5, 1.5);
    this.addChild(this.logo);
    this.zIndex = Z_INDEX_WAITING_LOGO;

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      if (
        setGameMode() === GameMode.BASE_GAME_GAMBLE ||
        SlotMachine.getInstance().state === SlotMachineState.GAMBLE_SPIN
      )
        return;
      this.waitLogoStart();
    });
    eventManager.addListener(EventTypes.GAMBLE_SPIN, this.waitLogoStart.bind(this));
    eventManager.addListener(EventTypes.GAMBLE_CANCEL, this.waitLogoStart.bind(this));

    eventManager.addListener(EventTypes.GAMBLE_CANCEL_COMPLETED, () => {
      this.clear();
    });
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, () => {
      this.clear();
    });
    eventManager.addListener(EventTypes.REEL_STOPPED, () => {
      this.clear();
    });
    eventManager.addListener(EventTypes.THROW_ERROR, () => {
      this.clear();
    });
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, () => {
      this.clear();
    });
  }

  private waitLogoStart() {
    this.clear();
    this.start();
  }

  private start() {
    const animationChain = new AnimationChain();
    const wait = Tween.createDelayAnimation(RESPONSE_WAITING_LOGO_DISPLAY_START_TIME);
    wait.addOnComplete(() => {
      if (SlotMachine.getInstance().isReadyForStop) return;

      this.logo.visible = true;
      const animation = this.createRotationLoopAnimation();
      animation.start();
    });
    animationChain.appendAnimation(wait);
    animationChain.start();
    this.rotateAnimation = animationChain;
  }

  private clear() {
    if (this.rotateAnimation) {
      this.rotateAnimation.skip();
      this.rotateAnimation = null;
      this.logo.visible = false;
    }
  }

  private createRotationLoopAnimation(perRotateAngle = 45, timeToNextAngle = 200) {
    const animationChain = new AnimationChain({ isLoop: true });
    for (let index = 1; index < 360 / perRotateAngle + 1; index++) {
      const nextAngle = index * perRotateAngle;
      const nextRotate = Tween.createDelayAnimation(timeToNextAngle);
      nextRotate.addOnComplete(() => {
        this.logo.angle = nextAngle;
      });
      animationChain.appendAnimation(nextRotate);
    }
    return animationChain;
  }
}
