import { useMutation } from '@apollo/client';
import { useCallback, useEffect } from 'react';

import { EventTypes, IGambleInput, ISettledBet } from '../../global.d';
import {
  setBalanceAmount,
  setGambleStake,
  setIsAfterSpin,
  setIsAutoSpins,
  setIsCollectSpin,
  setIsCollected,
  setNextResult,
  setPlayer,
  setSlotConfig,
  setSpinWithAutoSpin,
  setUserBalance,
} from '../../gql/cache';
import { gambleGql } from '../../gql/mutation';
import { eventManager } from '../../slotMachine/config';
import { caseFn } from '../../slotMachine/d';
import { debugDisplay } from '../../utils';

export const Collect: React.FC = () => {
  const [collect] = useMutation<{ selectOutcomes: ISettledBet }, { input: IGambleInput }>(gambleGql, {
    onError(error) {
      console.log(error);
    },
    async onCompleted(data) {
      setIsCollected(true);
      setSpinWithAutoSpin(false);
      setIsCollectSpin(false);
      setUserBalance(data.selectOutcomes.balance.settled);
      eventManager.emit(EventTypes.COLLECT_TIMEOUT, false);
      setGambleStake(0);
      eventManager.emit(EventTypes.DISABLE_COLLECT_BTN, true);
      eventManager.emit(EventTypes.DISABLE_GAMBLE_BTN, true);
      eventManager.emit(EventTypes.IS_GAMBLE_MODE, false);
      eventManager.emit(EventTypes.GAMBLE_CANCEL_COMPLETED);
      setNextResult(data.selectOutcomes);
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, data.selectOutcomes.balance.settled);
      setBalanceAmount(data.selectOutcomes.balance.settled.amount);
      debugDisplay('setIsAfterSpin', setIsAfterSpin(), 'setIsAutoSpins', setIsAutoSpins());
      if (setIsAfterSpin()) {
        eventManager.emit(EventTypes.AFTER_SPIN);
      } else if (setIsAutoSpins()) {
        eventManager.emit(EventTypes.AUTO_SPIN_COLLECT);
      }
    },
  });

  const gambleCancel = useCallback(() => {
    const { id: betId, coinAmount, coinValue, outcomes } = setNextResult()!.bet;
    const outcomeIds = outcomes.find((d) => d.predicament.caseFn === setPlayer())!.id;
    eventManager.emit(EventTypes.COLLECT_TIMEOUT, true);
    collect({
      variables: {
        input: {
          betId: betId,
          coinAmount: coinAmount,
          coinValue: coinValue,
          outcomeIds: outcomeIds,
          slotId: setSlotConfig().slotId,
        },
      },
    });
  }, [collect]);

  const onSpinButtonClick = useCallback(() => {
    gambleCancel();
  }, [gambleCancel]);

  useEffect(() => {
    eventManager.on(EventTypes.GAMBLE_CANCEL, () => {
      setPlayer(caseFn.closeGamble);
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.GAMBLE_CANCEL);
    };
  }, [onSpinButtonClick]);

  return null;
};
