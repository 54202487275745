import * as PIXI from 'pixi.js';

import { SlotId } from '../../config';
import { Events } from '../../global.d';

import TypedEmitter from './typedEmitter';

export * from './textStyles';
export const eventManager = new PIXI.utils.EventEmitter() as TypedEmitter<Events>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(window as unknown as any).eventManager = eventManager; // Trick to proxy eventManager to cheats file

export enum SlotMachineState {
  IDLE,
  GAMBLE_SPIN,
  SPIN,
  STOP,
  JINGLE,
  WINNING,
}

// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = false;
export const APPLICATION_FPS = 60;

// WIN SETTINGS
// export const WIN_ANIMATION_INTERVAL = 1_000;
export const WIN_ANIMATION_DELAY_TIME_DURATION = 1_000;
export const WIN_CHECK_TIME_DURATION_ON_FREE_SPIN = 1_000;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.2;
export const BASE_WIN_COUNT_UP_MULTIPLIER = 0.05;

// BIG WIN SETTINGS
export const BIG_WIN_TITLE = 'BIG WIN!';
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_TITLE_SCALE = 1.3;
export const BIG_WIN_ZOOM_TITLE_DURATION = 500;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.0265;
export const BIG_WIN_END_DURATION = 500;

// MEGA WIN SETTINGS
export const MEGA_WIN_TITLE = 'MEGA WIN!';
export const MEGA_WIN_AMOUNT_LIMIT = 50;
export const MEGA_WIN_TITLE_SCALE = 1.4;
export const MEGA_WIN_ZOOM_TITLE_DURATION = 550;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// GREAT WIN SETTINGS
export const GREAT_WIN_TITLE = 'GREAT WIN!';
export const GREAT_WIN_AMOUNT_LIMIT = 100;
export const GREAT_WIN_TITLE_SCALE = 1.5;
export const GREAT_WIN_ZOOM_TITLE_DURATION = 600;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2;

// EPIC WIN SETTINGS
export const EPIC_WIN_TITLE = 'EPIC WIN!';
export const EPIC_WIN_TITLE_SCALE = 1.6;
export const EPIC_WIN_ZOOM_TITLE_DURATION = 650;
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5;

// WIN SLOT ANIMATION SETTINGS
export const WIN_SLOT_ANIMATION_SCALE = 1.15;
export const WIN_SLOT_ANIMATION_DURATION = 600;
export const TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT = 1;
export const WIN_SLOT_TINT_COLOR = 0x888888;
export const SPRITE_ANIMATION_FPS = 30;

// GENERAL SLOT MACHINE SETTINGS
export const REELS_AMOUNT = 1;
export const SLOTS_PER_REEL_AMOUNT = 1;
export const REEL_WIDTH = 300;
export const REEL_HEIGHT = 260; // 250
export const SLOT_WIDTH = 300;
export const SLOT_HEIGHT = 180;
export const SLOT_SCALE = 1.0;
export const SHOW_ALL_LINES_ON_WIN = true;
export const SLOTS_CONTAINER_HEIGHT = REEL_HEIGHT * 3 + 12 * 2; // SLOTS_PER_REEL_AMOUNT;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * 3 + 10 * 2; //REELS_AMOUNT;
export const SHOW_LINES_NUMBER_CONTAINER = false;
export const LINES_NUMBER_CONTAINER_WIDTH = 30;
export const MINIMUM_SPIN_SLOTS_AMOUNT = 50;
export const GAME_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const GAME_CONTAINER_WIDTH = SHOW_LINES_NUMBER_CONTAINER
  ? SLOTS_CONTAINER_WIDTH + 2 * LINES_NUMBER_CONTAINER_WIDTH
  : SLOTS_CONTAINER_WIDTH;
export const SLOTS_BACKGROUND_HEIGHT = SLOTS_CONTAINER_HEIGHT + 20;
export const SLOTS_BACKGROUND_WIDTH = SLOTS_CONTAINER_WIDTH;

export const SLOTS_BASE_GAME_ADJUST = 100;
export const SLOTS_BATTLE_GAME_ADJUST = 30;

export const WIN_ANIM_Y_ADJUST = 6;
export const WIN_ANIM_X1_ADJUST = 194.5;
export const WIN_ANIM_X2_ADJUST = 601.5;
export const WIN_ANIM_X3_ADJUST = 1008.5;

// BATTLE BONUS UPPER COLOR WINDOW
export const BATTLE_BNS_UPPER_COLOR_POS_X = -610;
export const BATTLE_BNS_UPPER_COLOR_POS_Y = 120;

export const SP_ENDING_ROUND = 20;

// BATTLE BONUS Chara ICON
export const BATTLE_BNS_UPPER_BACKGROUND_POS_Y = -255;
export const BATTLE_BNS_UPPER_BACKGROUND_HEIGHT = 250;
export const BATTLE_BNS_UPPER_BACKGROUND_WIDTH = SLOTS_CONTAINER_WIDTH;

export const SLOTS_BB_ICON_BLANK = -21;
export const SLOTS_BB_ICON = -11;
export const BATTLE_BNS_CHARA_ICON_MAX = 5;

// REELS FRAME SETTINGS
export const REELS_FRAME_ADJUSTMENT = 32;

// ANTICIPATION SETTINGS
export const ANTICIPATION_ENABLE = true;
export const ANTICIPATION_SYMBOLS_ID = [SlotId.A];
export const ANTICIPATION_START_SYMBOLS_AMOUNT = [2];
export const ANTICIPATION_END_SYMBOLS_AMOUNT = [];

export const ANTICIPATION_DELAY = 0;
export const ANTICIPATION_DURATION = 3000;
export const ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT = 10;
export const ANTICIPATION_REEL_FORMULA = (t: number): number => 1 - (1 - t) * (1 - t);

export const ANTICIPATION_SLOTS_TINT = 0x121212; //0x7f7f7f;

// BATTLE BONUS MAIN  WINDOW
export const BATTLE_BONUS_MAIN_POS_X = -614;
export const BATTLE_BONUS_MAIN_POS_Y = -350;

// BATTLE BONUS MAIN  WINDOW
export const BATTLE_BONUS_R6_MAIN_POS_X = 0;
export const BATTLE_BONUS_R6_MAIN_POS_Y = -470;

// BASE GAME CHARA WINDOW
export const BASE_GAME_CHARA_WINDOW_POS_X = SLOTS_CONTAINER_WIDTH / 2;
export const BASE_GAME_CHARA_WINDOW_POS_Y = -125;

// REEL STOP SOUND SETTINGS
export const BONUS_SYMBOLS_ID = [[SlotId.A, SlotId.A, SlotId.A]];

export const GOLD_BONUS_SYMBOLS_ID = [[SlotId.A, SlotId.A, SlotId.A]];

export const RED_BONUS_SYMBOLS_ID = [[SlotId.A, SlotId.A, SlotId.A]];

export const BATTLE_BONUS_MESSAGE_ICON_SCALE = 0.75;
export const BATTLE_BONUS_MESSAGE_ICON_POS = [BATTLE_BONUS_MESSAGE_ICON_SCALE * SLOT_WIDTH * -1 - 70, 70];

// SAFE AREA SETTINGS
export const SAFE_AREA_DESKTOP_BOTTOM_PADDING = 0;
export const SAFE_AREA_MOBILE_BOTTOM_PADDING = 0;
export const SAFE_AREA_LANDSCAPE_WIDTH_MARGIN = 1100;
export const SAFE_AREA_LANDSCAPE_WIDTH = GAME_CONTAINER_WIDTH + SAFE_AREA_LANDSCAPE_WIDTH_MARGIN;
export const SAFE_AREA_LANDSCAPE_HEIGHT_MARGIN = 350;
export const SAFE_AREA_LANDSCAPE_HEIGHT = GAME_CONTAINER_HEIGHT + SAFE_AREA_LANDSCAPE_HEIGHT_MARGIN;
export const SAFE_AREA_LANDSCAPE_PIVOT_X = -SAFE_AREA_LANDSCAPE_WIDTH_MARGIN / 2;
export const SAFE_AREA_LANDSCAPE_PIVOT_Y = -280; //-180;
export const SAFE_AREA_PORTRAIT_WIDTH = 925; //SLOTS_CONTAINER_WIDTH + 50; // 498 * 1230/463
export const SAFE_AREA_PORTRAIT_HEIGHT = 1900; //SLOTS_CONTAINER_HEIGHT * 2; // 800 * 1230/463
export const SAFE_AREA_PORTRAIT_PIVOT_X = 0; // 18 * 1230/463
export const SAFE_AREA_PORTRAIT_PIVOT_Y = -SLOTS_CONTAINER_HEIGHT / 2; // 246 * 1230/463

// REELS SETTINGS
export const BASE_SPIN_TIME = 2000;
export const TURBO_SPIN_TIME = 1000;
export const ADDITIONAL_SPIN_TIME_PER_REEL = 250;
export const TURBO_ADDITIONAL_SPIN_TIME_PER_REEL = 100;
export const TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL = 25;
export const SPIN_REEL_ANIMATION_DELAY_PER_REEL = 50;
// export const PERSISTENT_SHARP_SYMBOLS_ID = [SlotId.SC1, SlotId.WL, SlotId.MS1];
export const BASE_REEL_BLUR_VALUE = 18;
export const TURBO_REEL_BLUR_VALUE = 24;
export enum ReelState {
  IDLE,
  STARTING,
  ROLLING,
  ENDING,
}

// SPIN ANIMATION SETTINGS
export const BASE_REEL_STARTING_DURATION = 500;
export const BASE_REEL_FIRST_ROLLING_DURATION = 500;
export const BASE_REEL_ROLLING_DURATION = 0;
export const BASE_REEL_ROLLING_SPEED = 0.015;
export const BASE_REEL_ENDING_DURATION = 250;
export const FAKE_ROLLING_DURATION = 12_000;
export const FAKE_ROLLING_GAMBLE_DURATION = 1200000000_000;
export const TURBO_REEL_STARTING_DURATION = 500;
export const TURBO_REEL_FIRST_ROLLING_DURATION = 500;
export const TURBO_REEL_ROLLING_DURATION = 0;
export const TURBO_REEL_ROLLING_SPEED = 0.015;
export const TURBO_REEL_ENDING_DURATION = 250;
export const FORCE_STOP_SPIN_ANIMATION_DURATION = 250;
export const FORCE_STOP_SPIN_PER_EACH_DURATION = 50;
export const REEL_STARTING_SLOTS_AMOUNT = (BASE_REEL_ROLLING_SPEED * BASE_REEL_STARTING_DURATION) / 3.4;
export const REEL_ENDING_SLOTS_AMOUNT = (BASE_REEL_ROLLING_SPEED * BASE_REEL_ENDING_DURATION) / 2; // (speed*duration) / (df/dt)(0)
export const BASE_REEL_STARTING_FORMULA = (t: number): number => t * t * (1.4 * t - 0.4);
export const BASE_REEL_ENDING_FORMULA = (t: number): number => 1 - (1 - t) * (1 - t);
// (t - 1) * (t - 1) * ((0.5 + 1) * (t - 1) + 0.5) + 1;
export const BASE_REEL_ENDING_SIM = (t: number): number => Math.sin(t);

export const BASE_REEL_ENDING_EASELINBOUNCE = (t: number): number => 1 - BASE_REEL_ENDING_EASELINBOUNCE(1 - t);

export const BASE_REEL_PHOENIX_ROLLING_DURATION = 3500;
export const BASE_REEL_PHOENIX_ROLLING_DURATION_TURBO = 4500;

// GAME TITLE LOGO
export const GAME_TITLE_LOGO_X_LANDSCAPE_POSITION = 455; // 611/2 + 300/2
export const GAME_TITLE_LOGO_Y_LANDSCAPE_POSITION = -245;
export const GAME_TITLE_LOGO_X_PORTRAIT_POSITION = GAME_TITLE_LOGO_X_LANDSCAPE_POSITION;
export const GAME_TITLE_LOGO_Y_PORTRAIT_POSITION = -350;

// TINT CONTAINER SETTINGS
export const TINT_CONTAINER_WIDTH = REEL_WIDTH;
export const TINT_CONTAINER_HEIGHT = SLOTS_BACKGROUND_HEIGHT;
export const TINT_BACKGROUND_COLOR = 0x000000;
export const TINT_ALPHA = 0.3;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = REEL_WIDTH;
export const PAY_TABLE_HEIGHT = REEL_HEIGHT;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;
export const PAY_TABLE_NUMBER_COLOR = 0xffffff;
export const PAY_TABLE_TEXT_COLOR = 0xf6e87e;

// COINS ANIMATIONS SETTINGS
export const COINS_AMOUNT_PER_SEC = 8;
export const SINGLE_COIN_ANIMATION_DURATION = 2500;
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;

export const WIN_ANIM_START_DELAY = 500;

// FREE SPINS
export const FREE_SPINS_FADE_OUT_DURATION = 1000;
export const FREE_SPINS_FADE_IN_DURATION = 1000;
export const FREE_SPINS_TIME_OUT_BANNER = 3500;

export const WIN_CHECK_TIME_DURATION_ON_FREESPIN = 1_000;
export const BIG_WIN_CHECK_TIME_DURATION_ON_FREESPIN = 3_000;

export const BB_BANNER_FADEOUT_TIME = 1000;

export const BGM_FADE_OUT_TIME = 100;
export const BGM_FADE_OUT_VOLUME = 0;
export const BGM_FADE_IN_ADJUSTMENT = 400;
export const BGM_FADE_IN_TIME = 100;

//
export const REELS_BACKGROUND_BASEGAME_LANDSCAPE_POS_X = 312;
export const REELS_BACKGROUND_BASEGAME_LANDSCAPE_POS_Y = SLOTS_CONTAINER_HEIGHT;
export const REELS_BACKGROUND_BASEGAME_PORTRAIT_POS_X = REELS_BACKGROUND_BASEGAME_LANDSCAPE_POS_X;
export const REELS_BACKGROUND_BASEGAME_PORTRAIT_POS_Y = 684;

export const REELS_BACKGROUND_GAMBLE_LANDSCAPE_POS_X = 312;
export const REELS_BACKGROUND_GAMBLE_LANDSCAPE_POS_Y = 724;
export const REELS_BACKGROUND_GAMBLE_PORTRAIT_POS_X = REELS_BACKGROUND_BASEGAME_LANDSCAPE_POS_X;
export const REELS_BACKGROUND_GAMBLE_PORTRAIT_POS_Y = 584;

// GAMBLE REEL STOP
export const GAMBLE_REEL_STOP = [
  [0, 0, 0, 0, 0, 0, 3000, 800, 1500],
  [0, 0, 0, 0, 0, 0, 800, 3000, 1500],
  [0, 0, 0, 0, 0, 0, 800, 1500, 3000],
];

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
}

// LINES SETTINGS
type Position = {
  [key: number]: {
    x: number;
    y: number;
  };
};
export const POSITION: Position = {
  0: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  1: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  2: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  3: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  4: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  5: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  6: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  7: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  8: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  9: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  10: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  11: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  12: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  13: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  14: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  15: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  16: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  17: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  18: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  19: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
};

// LINE NUMBERS CONTAINER SETTINGS
export const LINES_NUMBER_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 5;
export const RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 0;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS = [
  { id: 0, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 1, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 35 },
  { id: 2, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 70 },
  { id: 3, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 105 },
  { id: 4, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 140 },
  { id: 5, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 175 },
  { id: 6, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 210 },
  { id: 7, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 245 },
  { id: 8, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 280 },
  { id: 9, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 315 },
  { id: 10, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 350 },
  { id: 11, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 385 },
];
export const RIGHT_LINES_NUMBERS_CONTAINER = [
  { id: 12, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 13, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 32 },
  { id: 14, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 64 },
  { id: 15, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 96 },
  { id: 16, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 128 },
  { id: 17, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 160 },
  { id: 18, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 192 },
  { id: 19, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 224 },
  { id: 20, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 256 },
  { id: 21, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 288 },
  { id: 22, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 320 },
  { id: 23, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 352 },
  { id: 24, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 384 },
];

export const MESSAGE_BANNER_MOBILE_SCALE = 0.93;

// PHOENIX
export const PHOENIX_POX_X = -600;
export const PHOENIX_POX_Y = -200;

// SLOT_STOP_DISPLAY_SPRITE_COLOR
export const DEFAULT_SPRITE_COLOR = 0xffffff;
export const NOT_WIN_LINE_SPRITE_COLOR = 0x7f7f7f;

// MINI MAP
export const MINI_MAP_STATE_X = 0;
export const MINI_MAP_STATE_Y = 30; // -250;
export const MINI_MAP_STATE_Y_ADD = 70;

export const MINI_MAP_LINE_X = 46;
export const MINI_MAP_LINE_Y = 60; // -220;
export const MINI_MAP_LINE_Y_ADD = 70;

// AVATAR
export const AVATAR_LANDSCAPE_POS_X = -340;
export const AVATAR_LANDSCAPE_POS_Y = 1140;
export const AVATAR_PORTRAIT_POS_X = 200;
export const AVATAR_PORTRAIT_POS_Y = 1282;

export const AVATAR_EYE_RANDOM_TIMER = 2000;
export const AVATAR_EYE_UI_CONTROL_TIMER = 3000;

// Z_INDEX
export const Z_INDEX_BATTLE_BONUS_BACK = 0;
export const Z_INDEX_REELS_BACKGROUND = 1;
export const Z_INDEX_BATTLE_BNS_UPPER_BACKGROUND_CONTAINER = 2;
export const Z_INDEX_GAME_VIEW = 3;
export const Z_INDEX_SLOTS_STOP_DISPLAY = 4;
export const Z_INDEX_BATTLE_BONUS_PANEL = 5;
export const Z_INDEX_BASE_GAME_CHARA_WINDOW = 6;
export const Z_INDEX_BATTLE_BONUS_CONTAINER = 7;
export const Z_INDEX_BATTLE_START = 8;
export const Z_INDEX_REELS_FRAME = 9;
export const Z_INDEX_MINI_PAY_TABLE = 10;
export const Z_INDEX_WIN_SLOTS_CONTAINER = 11;
export const Z_INDEX_GAME_TITLE = 13;
export const Z_INDEX_COINS_ANIMATION = 14;

export const Z_INDEX_NEARMISS = 17;
export const Z_INDEX_BUY_FEATURE_BTN = 10;
export const Z_INDEX_GAMBLE = 21;
export const Z_INDEX_WAITING_LOGO = 22;

export const Z_INDEX_WIN_COIN = 55;
export const Z_INDEX_WIN_LABEL = 92;
export const Z_INDEX_WIN_COUNT_UP_MESSAGE = 92;
export const Z_INDEX_BUY_BTN = 92;
export const Z_INDEX_BACKDROP = 93;
export const Z_INDEX_BUY_FEATURE_POPUP = 94;
export const Z_INDEX_BUY_FEATURE_POPUP_CONFIRM = 95;
export const Z_INDEX_BANNER = 96;
export const Z_INDEX_AVATAR = 97;
export const Z_INDEX_BACKDROP2 = 98;
export const Z_INDEX_PHOENIX = 1000;

// EYS CONTROL
export const EYE_CONTROL_IDLE_TIME = 30000;

export const REEL_FIND_ADJUSTMENT: number[] = [-7, 0, 7];

// BUY FEATURE BTN
export const FEATURE_BTN_LANDSCAPE_POSITION_X = -170;
export const FEATURE_BTN_LANDSCAPE_POSITION_Y = 18;
export const FEATURE_BTN_LANDSCAPE_WIDTH = 185;
export const FEATURE_BTN_LANDSCAPE_HEIGHT = 130;

export const FEATURE_BTN_PORTRAIT_POSITION_Y_MARGIN = 387.5;
export const FEATURE_BTN_PORTRAIT_POSITION_X = 778;
export const FEATURE_BTN_PORTRAIT_POSITION_Y = 950;

// GAMBLE BTN
export const GAMBLE_BTN_LANDSCAPE_POSITION_X = 1100;
export const GAMBLE_BTN_LANDSCAPE_POSITION_Y = 675;
export const GAMBLE_BTN_PORTRAIT_POSITION_X = 464;
export const GAMBLE_BTN_PORTRAIT_POSITION_Y = 730;

// COLLECT BTN
export const COLLECT_BTN_LANDSCAPE_POSITION_X = 1100;
export const COLLECT_BTN_LANDSCAPE_POSITION_Y = 540;
export const COLLECT_BTN_PORTRAIT_POSITION_X = 778;
export const COLLECT_BTN_PORTRAIT_POSITION_Y = GAMBLE_BTN_PORTRAIT_POSITION_Y;

export const COLLECT_BTN_GAMBLE_LANDSCAPE_POSITION_X = COLLECT_BTN_LANDSCAPE_POSITION_X;
export const COLLECT_BTN_GAMBLE_LANDSCAPE_POSITION_Y = 460;
export const COLLECT_BTN_GAMBLE_PORTRAIT_POSITION_X = COLLECT_BTN_PORTRAIT_POSITION_X;
export const COLLECT_BTN_GAMBLE_PORTRAIT_POSITION_Y = 660;

export const GAMBLE_BTN_GAMBLE_LANDSCAPE_POSITION_X = GAMBLE_BTN_LANDSCAPE_POSITION_X;
export const GAMBLE_BTN_GAMBLE_LANDSCAPE_POSITION_Y = 596;
export const GAMBLE_BTN_GAMBLE_PORTRAIT_POSITION_X = GAMBLE_BTN_PORTRAIT_POSITION_X;
export const GAMBLE_BTN_GAMBLE_PORTRAIT_POSITION_Y = COLLECT_BTN_GAMBLE_PORTRAIT_POSITION_Y;

// GAMBLE VIEW
export const GAMBLE_VIEW_DEALER_TEXT_LANDSCAPE_X = 464;
export const GAMBLE_VIEW_DEALER_TEXT_PORTRAIT_X = GAMBLE_VIEW_DEALER_TEXT_LANDSCAPE_X;
export const GAMBLE_VIEW_DEALER_TEXT_LANDSCAPE_Y = 162;
export const GAMBLE_VIEW_DEALER_TEXT_PORTRAIT_Y = 62;

export const GAMBLE_VIEW_PLAYER_TEXT_0_X = 150;
export const GAMBLE_VIEW_PLAYER_TEXT_1_X = 464;
export const GAMBLE_VIEW_PLAYER_TEXT_2_X = 778;

export const GAMBLE_VIEW_PLAYER_TEXT_LANDSCAPE_Y = 765;
export const GAMBLE_VIEW_PLAYER_TEXT_PORTRAIT_Y = 690;

export const GAMBLE_VIEW_LANDSCAPE_Y = -100;
export const GAMBLE_VIEW_PORTRAIT_Y = -150;

// GAME VIEW SLOTS CONTAINER
export const GAME_VIEW_SLOTS_CONTAINER_1_LANDSCAPE_Y = -185.5;
export const GAME_VIEW_SLOTS_CONTAINER_1_PORTRAIT_Y = -305.5;
export const GAME_VIEW_SLOTS_CONTAINER_2_GAMBLE_LANDSCAPE_Y = 13.5;
export const GAME_VIEW_SLOTS_CONTAINER_2_GAMBLE_PORTRAIT_Y = -126.5;
export const GAME_VIEW_SLOTS_CONTAINER_2_LANDSCAPE_Y = 89.5;
export const GAME_VIEW_SLOTS_CONTAINER_2_PORTRAIT_Y = -30;
export const GAME_VIEW_SLOTS_CONTAINER_3_GAMBLE_LANDSCAPE_Y = 286.5;
export const GAME_VIEW_SLOTS_CONTAINER_3_GAMBLE_PORTRAIT_Y = 146.5;
export const GAME_VIEW_SLOTS_CONTAINER_3_LANDSCAPE_Y = 365;
export const GAME_VIEW_SLOTS_CONTAINER_3_PORTRAIT_Y = 245;

// BUY FEATURE POPUP
export const FEATURE_POPUP_WIDTH = 600;
export const FEATURE_POPUP_HEIGHT = 295;
export const FEATURE_POPUP_TEXT_ALPHA = 0.25;
export const FEATURE_POPUP_TITLE_POSITION_Y = -271;
export const FEATURE_POPUP_TITLE_POSITION_X = 0;
export const FEATURE_POPUP_CANCEL_BTN_WIDTH = 282;
export const FEATURE_POPUP_CANCEL_BTN_HEIGHT = 109;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_Y = 167;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X - 200;
export const FEATURE_POPUP_OK_BTN_WIDTH = 282;
export const FEATURE_POPUP_OK_BTN_HEIGHT = 109;
export const FEATURE_POPUP_OK_BTN_POSITION_Y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
export const FEATURE_POPUP_OK_BTN_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X + 200;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y = -147;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y = -72;
export const FEATURE_POPUP_AMOUNT_TEXT_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X;
export const FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y = 0;
export const FEATURE_POPUP_INPUT_WIDTH = 454;
export const FEATURE_POPUP_INPUT_HEIGHT = 114;

export const FEATURE_POPUP_INPUT_POSITION_Y = 90;
export const FEATURE_POPUP_INPUT_POSITION_X = 0;

export const FEATURE_POPUP_MINUS_BTN_WIDTH = 113;
export const FEATURE_POPUP_MINUS_BTN_HEIGHT = 120;
export const FEATURE_POPUP_MINUS_BTN_POSITION_Y = 90;
export const FEATURE_POPUP_MINUS_BTN_POSITION_X = -FEATURE_POPUP_INPUT_WIDTH / 2 - FEATURE_POPUP_MINUS_BTN_WIDTH / 2;
export const FEATURE_POPUP_PLUS_BTN_WIDTH = FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_PLUS_BTN_HEIGHT = FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_PLUS_BTN_POSITION_Y = 90;
export const FEATURE_POPUP_PLUS_BTN_POSITION_X = FEATURE_POPUP_INPUT_WIDTH / 2 + FEATURE_POPUP_MINUS_BTN_WIDTH / 2;
export const FEATURE_POPUP_BET_VALUE_POSITION_Y = FEATURE_POPUP_INPUT_POSITION_Y;
export const FEATURE_POPUP_BET_VALUE_POSITION_X = 0;
export const FEATURE_POPUP_MIN = 1;
export const FEATURE_POPUP_MAX = 40;

export const FEATURE_POPUP_BASE_POS_X = 0;
export const FEATURE_POPUP_BASE_POS_Y = 75;
export const FEATURE_POPUP_POS_X = 15;
export const FEATURE_POPUP_POS_Y = -65;
export const FEATURE_POPUP_TITLE_POS_X = -10;
export const FEATURE_POPUP_TITLE_POS_Y = 640;

export const FEATURE_POPUP_BB_BTN_POS_X = GAME_CONTAINER_WIDTH / 2 - 250;
export const FEATURE_POPUP_BB_BTN_POS_Y = 125;
export const FEATURE_POPUP_HBB_BTN_POS_X = GAME_CONTAINER_WIDTH / 2 + 250;
export const FEATURE_POPUP_HBB_BTN_POS_Y = FEATURE_POPUP_BB_BTN_POS_Y;
export const FEATURE_POPUP_BB_TITLE_POS_X = FEATURE_POPUP_BB_BTN_POS_X;
export const FEATURE_POPUP_BB_TITLE_POS_Y = FEATURE_POPUP_BB_BTN_POS_Y + 50;
export const FEATURE_POPUP_HBB_TITLE_POS_X = FEATURE_POPUP_HBB_BTN_POS_X;
export const FEATURE_POPUP_HBB_TITLE_POS_Y = FEATURE_POPUP_BB_TITLE_POS_Y;
export const FEATURE_POPUP_MAX_LEVEL_TITLE_POS_X = FEATURE_POPUP_HBB_BTN_POS_X;
export const FEATURE_POPUP_MAX_LEVEL_TITLE_POS_Y = 287;
export const FEATURE_POPUP_BB_COST_POS_X = FEATURE_POPUP_BB_BTN_POS_X;
export const FEATURE_POPUP_BB_COST_POS_Y = FEATURE_POPUP_BB_BTN_POS_Y + 200;
export const FEATURE_POPUP_HBB_COST_POS_X = FEATURE_POPUP_HBB_BTN_POS_X;
export const FEATURE_POPUP_HBB_COST_POS_Y = FEATURE_POPUP_BB_COST_POS_Y;

// BUY FEATURE CONFIRM
export const FEATURE_POPUP_CONFIRM_WIDTH = 356;
export const FEATURE_POPUP_CONFIRM_HEIGHT = FEATURE_POPUP_HEIGHT;
export const FEATURE_POPUP_CONFIRM_POPUP_BG_WIDTH = 1173;
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_X = GAME_CONTAINER_WIDTH / 2;
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y = -220;
export const FEATURE_POPUP_CONFIRM_FREE_SPINS_TITLE_POS_X = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
export const FEATURE_POPUP_CONFIRM_FREE_SPINS_TITLE_POS_Y = 140;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y + 150;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y + 100;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_WIDTH = 282;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y + 120;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X = -FEATURE_POPUP_CONFIRM_CANCEL_BTN_WIDTH / 2 - 50;
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y;
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X = FEATURE_POPUP_CONFIRM_CANCEL_BTN_WIDTH / 2 + 50;
export const FEATURE_POPUP_CONFIRM_POSITION_X = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
export const FEATURE_POPUP_CONFIRM_POSITION_Y = 250;
export const FEATURE_POPUP_CONFIRM_MAX_LEVEL_TITLE_POS_X = GAME_CONTAINER_WIDTH / 2;
export const FEATURE_POPUP_CONFIRM_MAX_LEVEL_TITLE_POS_Y = 250;

// export const REEL_STOP_INFO = [0, 3, 7, 4, 8, 2, 5, 1, 6];
// export const REEL_STOP_INFO2 = [0, 1, 2, 3, 4, 5, 6, 7, 8];
export const REEL_STOP_INFO3 = [0, 7, 5, 1, 3, 6, 8, 2, 4];

export const PHOENIX_DIRECTION_TOTAL_BET = 200;

export const REPLAY_END_DELAY = 1000;

// RESPONSE WAITING LOGO
export const RESPONSE_WAITING_LOGO_DISPLAY_START_TIME = 5_000;
