import { GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';

export const BACKGROUND_SIZE_WIDTH = 1920;
export const BACKGROUND_SIZE_HEIGHT = 1080;

export const HEIGHT_BASE = 850;

export type BgSkin = 'default';

export const backGroundGameModeSkins: Record<GameMode, { bg: ResourceTypes; thicket: ResourceTypes }> = {
  [GameMode.BASE_GAME]: { bg: ResourceTypes.slotBg, thicket: ResourceTypes.slotBg },
  [GameMode.BUY_FEATURE]: { bg: ResourceTypes.slotBg, thicket: ResourceTypes.slotBg },
  [GameMode.FREE_SPIN]: { bg: ResourceTypes.slotBgF, thicket: ResourceTypes.slotBgF },
  [GameMode.BASE_GAME_GAMBLE]: { bg: ResourceTypes.slotBgF2, thicket: ResourceTypes.slotBgF2 },
};
